export default [
    {
        text: "About",
        path: "/",
    },
    {
        text: "Resume",
        path: "/resume",
    },
];
