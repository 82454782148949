export default {
    meta: {
        baseTitle:
            "Jacob Hurst | Software Engineer",
    },
    socials: [
        {
            url: "https://www.instagram.com/jacobhurst/",
            title: "Instagram",
            icon: "la-instagram",
        },
        {
            url: "https://www.linkedin.com/in/jacobdhurst/",
            title: "LinkedIn",
            icon: "la-linkedin-in",
        },
        {
            url: "https://github.com/jacobdhurst",
            title: "GitHub",
            icon: "la-github",
        },
    ],
    about: {
        title: "About Me",
        info: [
            {
                name: "Name",
                value: "Jacob Hurst",
            },
            {
                name: "Email",
                value: "jacob.hurst.94@gmail.com",
            },
            {
                name: "Job",
                value: "Software Engineer",
            },
        ],
        body: {
            header: "Background",
            text: `<p>I am a Software Engineer at Amazon and a proud alum of the University of New Mexico. Apart from software engineering (and technology in general), I enjoy running, gaming, reading, collecting records, brewing kombucha, traveling, and taking care of my pets and plants. If you have any interest in technology or simply wish to connect, please feel free to reach out to me via email.</p>`,
        },
        skills: [
            {
                title: "Java",
                progress: 90,
            },
            {
                title: "Kotlin",
                progress: 90,
            },
            {
                title: "JavaScript",
                progress: 90,
            },
            {
                title: "TypeScript",
                progress: 90,
            },
            {
                title: "Python",
                progress: 70,
            },
            {
                title: "Ruby",
                progress: 70,
            },
            {
                title: "AWS",
                progress: 90,
            },
            {
                title: "Spring",
                progress: 80,
            },
            {
                title: "Selenium",
                progress: 70,
            },
            {
                title: "Cypress",
                progress: 80,
            },
        ],
        services: [
            {
                icon: "la-code",
                header: "Development",
                description: "Proficient in collaborating with cross-functional teams, implementing agile methodologies, and engaging in code, requirements, and design document development and review.",
            },
            {
                icon: "la-server",
                header: "Services",
                description: "Proficient in developing RESTful web APIs, constructing infrastructure for scalable systems, and deploying services. ",
            },
        ],
    },
    resume: {
        title: "My Resume",
        education: [
            {
                title: "Bachelor of Engineering in Computer Science",
                subtitle: "University of New Mexico",
                date: {
                    start: "2015",
                    finish: "2019",
                },
                bullets: [
                    "Completed a rigorous program, earning a Bachelor of Engineering degree in Computer Science.",
                    "Pursued a minor in Mathematics, expanding knowledge and skills in quantitative reasoning.",
                    "Graduated with honors, achieving Magna Cum Laude distinction with a notable 3.7 GPA."
                ],
            }
        ],
        experience: [
            {
                title: "Software Engineer",
                subtitle: "Amazon",
                date: {
                    start: "July 2019",
                    finish: "Present",
                },
                bullets: [
                    "Designs and implements technical solutions to enhance backend and frontend services on the Bulk Buying and Quoting team, addressing the bulk ordering needs of business customers.",
                    "Leads cross-functional coordination among three teams and QA, successfully launching and maintaining a new product that facilitates quote requests and fulfillment between businesses and sellers.",
                    "Drives operational excellence initiatives, working closely with management to establish and achieve service-level agreements and key performance indicators.",
                    "Automates deployment and monitoring processes by developing test infrastructure, logic, canaries, and dashboards.",
                    "Orchestrates system preparation and team coordination during peak traffic periods, ensuring smooth service delivery.",
                    "Mentors and provides guidance to new team members and interns.",
                    "Is recognized with multiple leadership awards for exemplary performance.",
                ]
            },
            {
                title: "Software Engineer",
                subtitle: "Alliance DNA Laboratory",
                date: {
                    start: "August 2018",
                    finish: "May 2019",
                },
                bullets: [
                    "Contributed as an independent contractor, developing Python-based software for data collection and analysis on anthropological heritage utilizing DNA profiles.",
                    "Configured a SQL database housing genetic and population data for efficient retrieval and storage.",
                    "Designed an intuitive frontend user interface, ensuring a seamless user experience.",
                ]
            },
            {
                title: "System Specialist",
                subtitle: "University of New Mexico",
                date: {
                    start: "January 2018",
                    finish: "May 2019",
                },
                bullets: [
                    "Supported the University of New Mexico Computer Science department’s System Support Group, maintaining computer labs, servers, and critical systems.",
                    "Managed accounts and workstations, utilizing Puppet and Subversion for efficient system configuration management.",
                    "Ensured top-level security through access controls, backups, and firewalls.",
                    "Resolved various system-related issues, performing troubleshooting and addressing outages promptly.",
                ]
            },
            {
                title: "Teaching Assistant",
                subtitle: "University of New Mexico",
                date: {
                    start: "August 2017",
                    finish: "May 2019",
                },
                bullets: [
                    "Collaborated with multiple professors in the University of New Mexico Computer Science department to support several courses, including CS 351L - Design of Large Programs, CS 152L - Java, CS 151L - Python for Engineering, and CS 105L - Introduction to Computer Programming using JavaScript.",
                    "Managed a team of four teaching assistants, overseeing their performance and providing guidance.",
                    "Ensured fairness in grading, addressing exam regrade requests and conducting data analysis to identify common errors made by teaching assistants during grading in order to improve the grading process.",
                    "Conducted regular office hours to assist students, addressing their questions and offering exam review sessions.",
                ]
            },
        ],
    },
    copyrightText: `Copyright &copy; ${new Date().getFullYear()} - All rights reserved`,
};
